import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const ThankYouQuotationPage = ({ data }) => (
  <Layout>
    <SiteMetadata
      title="Sobre mim | Pri Bellafronte"
      description="Meu nome é Priscilla Bellafronte, sou designer gráfico e essa página contra um pouco sobre mim"
    />

    <div className="container py-12 lg:pb-16">
      <p>Muito obrigado! Já recebi sua solicitação de orçamento.</p>
      <br />
      <p>Vou avaliar e responder em no máximo 2 dias úteis :)</p>
    </div>
  </Layout>
)

export default ThankYouQuotationPage
